export enum ResourceTypes {
  PRODUCT_VIDEOS = "product-videos",
  BLOGS = "blogs",
  OLD_BLOGS = "b",
  CASE_STUDY = "case-study",
  RELEASES = "releases",
}

export interface Resource {
  id: string;
  name: string;
  slug: string;
  thumbnail: {
    url: string;
  };
  description: string;
  short_description: string;
  video: string;
  createdAt: string;
  categories?: Category[];
  toc?: string;
  related_blogs?: Resource[];
  category?: {
    name: string;
    slug: string;
  };
}

export interface GetResourceListParams {
  page: number;
  page_size: number;
  type: ResourceTypes;
  categories?: string | string[];
}

export interface GetResourceListResponse {
  data: Resource[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface Category {
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetCategoryListResponse {
  data: Category[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}
