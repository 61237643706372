import { Popover } from "antd";
import React, { useState } from "react";
import Colors from "~/constants/colors";
import Button from "../Button";
import Icon from "../Icon";
import styles from "./ChatButton.module.scss";

const ChatButton: React.FC = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const isBrowser = typeof window !== "undefined";

  const handleOpenPopover = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  return (
    <div className={styles.get_started_button}>
      <Popover
        arrow={false}
        trigger={["click"]}
        open={isOpenPopover}
        placement={
          isBrowser && window.innerWidth > 768 ? "rightBottom" : "bottom"
        }
        rootClassName={styles.popover_container}
        overlayClassName={styles.overlay}
        onOpenChange={(isvisible) => !isvisible && setIsOpenPopover(false)}
        content={
          isOpenPopover && (
            <div className={styles.popover}>
              <Button
                icon="support-24"
                type="borderless"
                className={styles.book_meeting_btn}
                onClick={() => {
                  window.open(
                    "https://calendar.app.google/9YB8GKe3ZgNt1z3z7",
                    "_blank"
                  );
                }}
              >
                Book Meeting
              </Button>
              <object
                className="w-full h-full"
                data="https://widget.cxgenie.ai?agent-id=6f5228d6-d3af-4e42-9ba8-16e4d1014953&is-mobile=false"
              />
            </div>
          )
        }
      >
        <div className={styles.wrapper} onClick={handleOpenPopover}>
          <Icon name="support-24" size={32} color={Colors.GREY_00} />
        </div>
      </Popover>
    </div>
  );
};

export default ChatButton;
